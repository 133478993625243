import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { FirebaseService } from '../../services/FirebaseService';
import showToast from '../../common/toastify';
import { CustomSwal } from '../../common/SwalMixin';
import { sleep } from '../../common/UtilitiyFunctions';
import { AppService } from '../../services/AppService';

const VerifyEmailComponent = ({...props}) => {

  const resendInterval = 30;        // 30 SECONDS
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorShown, setErrorShown] = useState(false);
  const ct = new Date();
  ct.setSeconds(ct.getSeconds() + resendInterval);

  const {
    seconds,
    restart
  } = useTimer({
    expiryTimestamp: ct, onExpire: () => {
      setIsDisabled(false);
    }
  });

  const sendEmailVerification = async () => {
    try {
      const { sent } = await FirebaseService.verifyEmail();
      // CustomSwal.fire('Sent', 'A verification email has been sent to you. Please head over to your mailbox and click on verification link.');
      if(sent){
      showToast('Verification email sent!');
      // CustomSwal.fire({
      //   title: "Sent",
      //   text: "A verification email has been sent to you. Please head over to your mailbox and click on verification link",
      //   type: "success",
      //   confirmButtonText: "Ok",
      // })
      setIsDisabled(true);
      const time = new Date();
      time.setSeconds(time.getSeconds() + resendInterval);
      restart(time);
      }
    } catch (e) {
      console.log(e);
      if (e.code === 'auth/too-many-requests') {
        showToast('Oops! Too many tries. Please try again later!')
      }
    }

  }

  useEffect(() => {
    setTimeout(async () => {
      if (FirebaseService?.auth?.currentUser && !FirebaseService?.auth?.currentUser?.emailVerified){
          await FirebaseService.verifyEmail(errorShown);
          setErrorShown(true)
        }
    }, 3000);
  }, [])

  const checkUserEmailVerifiedUpdate = async () => {
    let emailVerified = false;
    while (!emailVerified) {
      if (FirebaseService.auth.currentUser) {
        await FirebaseService.auth?.currentUser?.reload();
        emailVerified = FirebaseService.auth?.currentUser?.emailVerified || false;
        if (emailVerified) { }
      }
      await sleep(600);
    }
    const idToken = await FirebaseService.auth.currentUser.getIdToken();
    // const messagingToken = await FirebaseService.getMessagingToken();
    await AppService.doServerLogin(idToken, "",emailVerified);
  }

  useEffect(() => {
    checkUserEmailVerifiedUpdate();
    localStorage.setItem('tab1-open', 'true');
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('tab1-open');
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.removeItem('tab1-open');
      });
    }
  }, [])


  return (
    <>
     {/* <h2 class="heading2">Verify Your Email Address</h2> */}
     <p>Please click on the link that has just been sent to {props?.email} and verify your email.</p>
    <div className='text-center login-form'>
      {/* {
        isDisabled ?
          <>
            <p>Waiting for you....</p>
            </>
          :
          ""
          
        } */}
         <small><b>Waiting for you....</b></small>
        <button className="btn-w-full btn-gray mt-4 mb-0" onClick={() => !isDisabled && sendEmailVerification()} disabled={isDisabled}>{isDisabled ? `You can resend verification in ${seconds} seconds` : 'Resend Verification Email'}</button>
    </div>
    </>
  )
}

export default VerifyEmailComponent;
