import Swal from 'sweetalert2'

const getButtonClassByType = (type) => {
  let buttonClass = '';
  switch (type) {
    case 'primary':
      buttonClass = 'btn-w-fix btn-gray';
      break;
    case 'secondary':
      buttonClass = 'btn-w-fix btn-outline-gray';
      break;
    case 'success':
      buttonClass = 'btn btn-gradient-success btn-fw';
      break;
    case 'danger':
      buttonClass = 'btn btn-gradient-danger btn-fw';
      break;
    case 'warning':
      buttonClass = 'btn btn-gradient-warning btn-fw';
      break;
    case 'info':
      buttonClass = 'btn btn-gradient-info btn-fw';
      break;
    case 'light':
      buttonClass = 'btn btn-gradient-light btn-fw';
      break;
    case 'dark':
      buttonClass = 'btn btn-gradient-dark btn-fw';
      break;
    default:
      buttonClass = 'btn btn-gradient-primary btn-fw';
      break;
  }
  buttonClass += ' mx-1';
  return buttonClass;
}



export const SwalMixin = (
  confirm = 'primary',
  cancel = 'secondary',
  close = 'primary',
  deny = 'primary',
) => {
  const SwalWithThemeButtons = Swal.mixin({
    customClass: {
      confirmButton: getButtonClassByType(confirm),
      cancelButton: getButtonClassByType(cancel),
      closeButton: getButtonClassByType(close),
      denyButton: getButtonClassByType(deny),
    },
    buttonsStyling: false,
    iconColor: '#333'
  })

  return SwalWithThemeButtons;
}

export const CustomSwal = SwalMixin()
  