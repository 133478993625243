import React from "react";
import ReactDOM from "react-dom";
import showToast from "../common/toastify";
import { FirebaseService } from "./FirebaseService";
import VerifyEmailComponent from "../users/components/VerifyEmailComponent";
import { CustomSwal } from "../common/SwalMixin";
const DefaultErrorMessage = "An error has occured. Please contact support";



const showVerificationModal = (email) => {
  const modalComponent = document.createElement("div");
  ReactDOM.render(<VerifyEmailComponent email={email} />, modalComponent )  
  CustomSwal.fire({
    title: "Registered!",
    icon: "success",
    allowOutsideClick: false,
    allowEscapeKey: false,
    html: modalComponent,
    showConfirmButton: false,
   })
  }


const doServerLogin = async (idToken, registrationToken, emailVerified,loginType,firebaseUser) => {
  let url = window.location.pathname.includes('/admin-login') ? '/admin-login.json' : '/login.json'
  if(localStorage.getItem('shouldShowWelcomePopup') && !sessionStorage.getItem('bookingFlowData'))
  localStorage.removeItem('shouldShowWelcomePopup')
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken: idToken,
        registration_token: registrationToken
      })
    })
    const data = await response.json();
    console.log(data.redirect_url);
    if (data) {
      if (data.message == 'User not found. Please create your account first.') {
        showToast('You are not registered. Please sign up first.', 'error');
        await FirebaseService.deleteFirebaseUser();
      } else if (data.message == 'User is deactivated') {
        showToast('User is deactivated', 'error');
      } else if (data.message == "Email not verified") {
        // await FirebaseService.verifyEmail();
        // window.location.href = "/verify-user-email"
        if(loginType == 'phone') {
          showVerificationModal(firebaseUser?.email)
        }
      } else if (data.message == 'User role not assigned') {
        window.location.href = '/setup-user-role';
      } else if (data.user && data.user.id) {
        let redirect = data.redirect_url || '/';
        if (redirect == '/') {
          redirect = "/events?filter=2";
          // if(emailVerified)  localStorage.setItem('emailVerified', JSON.stringify({ verified: true, timestamp: Date.now() }));
        }
        console.log('redirect', redirect);
        window.location.href = redirect;
      } else {
        showToast(data.message || 'Unable to redirect.', 'error');
        console.log(data);
      }
    } else {
      showToast('Error in login, no response received from server.', 'error');
      console.log('Error in login, no response received from server');
    }
  } catch (e) {
    throw e;
  }
}

export const AppService = {
  DefaultErrorMessage,
  doServerLogin
}